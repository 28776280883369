import Sortable from "sortablejs";

try {
	document.querySelector(`body`).style.paddingTop = `${document.querySelector(`.navbar`).offsetHeight}px`;
} catch (error) {
	// Nevermind.
}

// $(function() {
// 	$('html').delegate('.navbar .nav a', 'click', function(e) {
// 		e.preventDefault();
// 		$(this).blur();
// 		var target = $('#' + $(this).attr('href').substr(1, 99));
// 		$('html, body').animate({
// 			scrollTop: target.offset().top - $('.navbar').height() + 1,
// 		}, 500);
// 	});
// });

document.addEventListener("click", (event) => {
	if (event.target.matches(`#aktuality h2 a`)) {
		event.preventDefault();
		document.querySelector(`#news`).classList.toggle("hidden");
	}
});

document.querySelectorAll(`table.sortable tbody`).forEach(el => {
	Sortable.create(el, {
		handle: `.sortable-handle`,
		onEnd: (event) => {
			const ids = Array.from(el.querySelectorAll(`tr`)).map((tr) => {
				return parseInt(tr.dataset.id);
			});
			fetch("/admin/cenik/poradi", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					ids: ids,
				}),
			}).then(response => {
				console.log(response);;
			});
		},
	});
});
